import store from '@/store'
import Vue from 'vue'
import i18n from '@/libs/i18n/index'
import router from '@/router'
import AToastificationContent from '@core/components/toastification/ToastificationContent'
import backendApiList from '@/backendApiList'
import globalConfig from '@/global-config'
import { srHub, srStart, srStop, srUpdateFilter, srStateMap } from '@/libs/signalr-hub'
import { encrypt, decrypt } from '@/libs/ez-utils'

import axios from '@/libs/axios'
// import axiosWh from '@/libs/axios-wehealth' // use "Vue.prototype.$requestWehealth" instead

export default {
  config: globalConfig,

  // 获取对应环境的URL
  getServerUrl() {
    return process.env.VUE_APP_API_BASE_URL
  },

  // api头部
  getApiHeaders() {
    let lang = ''
    switch (this.getLocale()) {
      case 'zh_HK':
        lang = 'zh-HK'
        break
      case 'zh_CN':
        lang = 'zh-CN'
        break
      case 'en':
        lang = 'en-US'
        break
      case 'zh_TW':
        lang = 'zh-TW'
        break
    }

    return {
      'Authorization': {
        // fetch newest token in every return
        toString: () => 'Bearer ' + this.getAccessToken()
      },
      'Accept-Language': lang
    }
  },

  // api get方法
  apiGetData(api, is_loading = true, showMsg = true, showUnknownError = true) {
    const url = this.apiPrepareUrl(api)
    this.apiShowLoading(is_loading)
    const headers = this.getApiHeaders()
    const timeout = this.config.api_timeout

    return axios.get(url, {
      headers: headers,
      timeout: timeout
    })
      .then(res => {
        return this.apiHandleResponse(res, is_loading, showMsg)
      })
      .catch(err => {
        return this.apiHandleError(err, is_loading, showUnknownError)
      })
  },

  // api post 方法
  apiPostData(api, data, is_loading = true, showMsg = true, showUnknownError = true) {
    const url = this.apiPrepareUrl(api)
    this.apiShowLoading(is_loading)
    const headers = this.getApiHeaders()
    const timeout = this.config.api_timeout

    return axios.post(url, data, {
      headers: headers,
      timeout: timeout,
    })
      .then(res => {
        return this.apiHandleResponse(res, is_loading, showMsg)
      })
      .catch(err => {
        return this.apiHandleError(err, is_loading, showUnknownError)
      })
  },

  // api 下载
  apiDownloadData({
    responseType = 'blob',
    headers = this.getApiHeaders(),
    timeout = this.config.api_download_timeout,
    method = 'get',
    url = '',
    data = {},
    params = {},
  }) {
    console.log(`"${url}" timeout: ${timeout}`)
    this.apiShowLoading(true)
    return axios({
      url: this.apiPrepareUrl(url),
      responseType,
      headers,
      timeout,
      method,
      data,
      params,
    })
      .then(res => {
        // console.log(res)
        this.hideLoading()
        if (![0, undefined, null].includes(res.data.return_code)) {
          this.showError(res.data.result_msg ? res.data.result_msg : 'UnknownError')
          this.throwError()
          return false
        }
        let blob = new Blob([res.data])
        let objectUrl = URL.createObjectURL(blob)
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display:none')
        a.setAttribute('href', objectUrl)
        let filename = res.headers['content-disposition'].split(';')[1].replace(/^.+="?([^"]+)"?$/, '$1')
        a.setAttribute('download', filename)
        a.click()
        URL.revokeObjectURL(objectUrl)
      })
      .catch(err => {
        this.apiHandleError(err, true)
      })
  },

  apiBatchUpload({
    file = null,
    clearFile = () => {
    },
    api = '',
  }) {
    const suffix = file.name.slice(file.name.lastIndexOf('.'))
    if (['.xlsx', '.xls'].indexOf(suffix) >= 0) {
      const formData = new FormData()
      // formData.append('ExcelFile', this.file)
      formData.append('facilityID', this.getFacilityId())
      formData.append('ExcelFile', file)
      this.apiPostData(api, formData, true, false, false)
        .then(res => {
          if (res.return_code === 0) { // upload success
            this.showToast(this.getI18n('batchupload.upload_success'))
            clearFile()
          } else if (res.return_code !== undefined) { // upload error, server responded

            if (res.result_msg && typeof res.data === 'object') { // file-content error
              let errorRowMsg = '',
                errorContentMsg = ''
              if (typeof res.data['errorRow'] === 'number' && res.data['errorRow'] > 0) {
                errorRowMsg = this.getI18n('batchupload.errorRowMsg')
                  .replace('{rowNum}', res.data['errorRow']) + ' '
              }
              if (typeof res.data['errorContent'] === 'string' && res.data['errorContent'] !== '') {
                // 返回的 errorContent 會是excel表中出錯的cell的內容
                errorContentMsg = ': ' + res.data['errorContent']
              }
              this.showAlert(errorRowMsg + this.getI18n('error.' + res.result_msg) + errorContentMsg)
              clearFile()
            } else { // non-file-content error
              this.showAlert(res.result_msg ? this.getI18n(`error.${res.result_msg}`) : this.getI18n('error.ServerError'))
            }

          } else { // other error, maybe file has changed
            this.showAlert(this.getI18n('batchupload.unknownErrorMsg'))
            clearFile()
          }
        })
    } else {
      this.showAlert(this.getI18n('batchupload.file_type_hint'))
      clearFile()
    }
  },

  // 返回api完整URL
  apiPrepareUrl(url) {
    if (!this.config.is_request_mock && this.config.check_backend_api) {
      let tmp = url
      if (url.indexOf('?') !== -1) {
        tmp = url.substring(0, url.indexOf('?'))
      }
      if (!backendApiList.includes(tmp)) {
        console.log(tmp)
        this.showError('UrlNotFound')
      }
    }

    return this.getServerUrl() + 'api' + url
  },

  // 处理api返回的数据
  apiHandleResponse(res, is_loading, showMsg = true) {
    if (is_loading) {
      this.hideLoading()
    }

    if (res.data.return_code !== 0 && showMsg) {
      this.showError(res.data.result_msg ? res.data.result_msg : 'UnknownError')
      this.throwError()
      return false
    }

    return res.data
  },

  // 处理api返回错误时的数据
  apiHandleError(err, is_loading, showUnknownError = true) {
    if (is_loading) {
      this.hideLoading()
    }

    const errMsg = err.message ? err.message : ''
    const errStatus = err.response ? err.response.status : undefined
    if (errMsg.indexOf('timeout') !== -1) {
      this.showError('apiRequestTimeout')
    } else if (errMsg?.toLowerCase()
      .indexOf('network error') >= 0) {
      this.showAlert({ title: this.getI18n('error.' + 'apiRequestNetworkError') })
    } else if (errStatus === 400) {
      if (err.response.data?.errors) {
        let errorMsg = 'Error:'
        for (const i in err.response.data.errors) {
          errorMsg += ' ' + err.response.data.errors[i][0]
        }
        this.showAlert({ title: errorMsg })
      } else {
        this.showAlert({ title: this.getI18n('error.' + 'UnknownError') + ': ' + errStatus })
      }
    } else if (errStatus === 401 || errStatus === 403) {
      this.logout()
    } else if (errStatus === 404) {
      this.showError('UrlNotFound')
    } else if (500 <= errStatus && errStatus <= 599) {
      this.showAlert({ title: this.getI18n('error.ServerError') + ': ' + errStatus })
    } else if (errMsg) {
      this.showAlert(errMsg)
    } else if (err.name !== 'SyntaxError' && showUnknownError) {
      const extraMsg = errStatus ? ': ' + errStatus : ''
      this.showAlert({ title: this.getI18n('error.UnknownError') + extraMsg })
    }
    this.throwError()
    return err
  },

  // api加载的圈圈
  apiShowLoading(is_loading) {
    if (is_loading) {
      this.showLoading()
    }
  },

  // 抛出异常，中止代码执行
  throwError() {
    throw SyntaxError()
  },

  // 显示加载中的圈圈
  showLoading() {
    store.commit('app/INC_LOADING_NUM')
    // store.commit('app/TOGGLE_LOADING', true)
  },

  // 隐藏加载中的圈圈
  hideLoading() {
    store.commit('app/DEC_LOADING_NUM')
    // store.commit('app/TOGGLE_LOADING', false)
  },

  // 显示弹框提示
  showAlert({
    title = '',
    text = '',
    fun = null,
    icon = 'info'
  }) {
    if (typeof arguments[0] === 'string') {
      [title = '', text = '', fun = null, icon = 'info'] = [...arguments]
    }
    Vue.swal({
      title: title,
      text: text,
      icon,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      showClass: {
        popup: '',
        // backdrop: '',
        // icon: ''
      },
      hideClass: {
        popup: '',
        // backdrop: '',
        // icon: ''
      },
      buttonsStyling: false,
      confirmButtonText: i18n.t('common.confirm')
    })
      .then(res => {
        if (typeof (fun) === 'function') {
          fun()
        }
      })
  },

  // 显示确认提示
  showConfirm({
    title = '',
    text = '',
    html = '',
    confirm_fun = null
  }) {
    if (typeof arguments[0] === 'string') {
      [title = '', text = '', confirm_fun = null] = [...arguments]
    }
    Vue.swal({
      title,
      text,
      html,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: i18n.t('common.cancel'),
      confirmButtonText: i18n.t('common.confirm'),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary ml-1',
      },
      showClass: {
        popup: '',
        // backdrop: '',
        // icon: ''
      },
      hideClass: {
        popup: '',
        // backdrop: '',
        // icon: ''
      },
      buttonsStyling: false,
    })
      .then(res => {
        if (res.value && typeof (confirm_fun) === 'function') {
          confirm_fun()
        }
      })
  },

  // 显示上方的提示，会自动消失，如删除数据修改数据后的提示
  showToast({
    title = '',
    text = '',
    variant = 'success',
    icon = 'InfoIcon',
  }) {
    if (typeof arguments[0] === 'string') {
      [title = '', text = '', variant = 'success', icon = 'InfoIcon'] = [...arguments]
    }
    Vue.$toast({
      component: AToastificationContent,
      props: {
        title,
        text,
        icon,
        variant
      }
    }, {
      position: 'top-center'
    })
  },

  // 显示错误信息
  showError(key) {
    this.showAlert({ title: this.getI18n('error.' + key) })
    this.throwError()
  },

  refreshStore() {
    store.commit('app/REFRESH_MENU_ITEMS')
    store.commit('app/REFRESH_USER_PROFILE')
    store.commit('app/REFRESH_ROLE')
    store.commit('app/REFRESH_ADMIN_COMPANY_ID')
    store.commit('app/REFRESH_ADMIN_FACILITY_ID')
    store.commit('app/REFRESH_MENU_NAME')
    store.commit('app/REFRESH_PERMISSIONS')
    store.commit('app/REFRESH_LOCALE')
    store.commit('app/REFRESH_MODULE_LABEL')
    store.commit('app/REFRESH_IMPERSONATED')
    store.commit('app/REFRESH_CUSTOM_CONFIGS')
  },

  clearSpecificLocalStorageItems() {
    this.config.localStorageItemsToBeRemovedWhileLogout.forEach((item) => {
      localStorage.removeItem(item)
    })
    this.refreshStore()
  },

  setAccessToken(access_token) {
    localStorage.setItem('access_token', access_token)
  },

  getAccessToken() {
    return localStorage.getItem('access_token')
  },

  clearAccessToken() {
    localStorage.removeItem('access_token')
  },

  setRefreshToken(refresh_token) {
    localStorage.setItem('refresh_token', refresh_token)
  },

  getRefreshToken() {
    return localStorage.getItem('refresh_token')
  },

  setTokenExpireTime(expire_in) {
    localStorage.setItem('token_expire_in', (new Date().getTime() / 1000 + expire_in).toString())
  },

  isTokenExpire() {
    const expire_time = localStorage.getItem('token_expire_in')
    return new Date().getTime() >= parseInt(expire_time) * 1000
  },

  setUserProfile(data) {
    store.commit('app/SET_USER_PROFILE', data)
  },

  getUserProfile() {
    return store.state.app.userProfile
  },

  clearUserProfile() {
    localStorage.removeItem('user_profile')
  },

  setRole(data) {
    store.commit('app/SET_ROLE', data)
  },

  getRole() {
    return store.state.app.role
  },

  getI18n(key) {
    return /^(?:[\w_]+.)+[\w_]+$/.test(key) ? i18n.t(key) : key
  },

  setMenuName(value) {
    store.commit('app/SET_MENU_NAME', value)
  },

  getMenuName(key) {
    return store.state.app.menuName[key]
  },

  setMenuItems(items) {
    store.commit('app/SET_MENU_ITEMS', items)
  },

  getMenuItems() {
    return store.state.app.menuItems
  },

  setMenuFirstRoute(route) {
    localStorage.setItem('first_route', route)
  },

  getMenuFirstRoute() {
    return localStorage.getItem('first_route')
  },

  getTableUrl(api, search, sort, page) {
    let url = api + '?'
    if (search) {
      url += this.transParamsToStr(search)
    }

    if (sort.orderBy) {
      url += this.transParamsToStr(sort)
    }

    url += this.transParamsToStr(page)

    return url
  },

  transParamsToStr(params) {
    let str = ''
    for (const key in params) {
      str += key + '=' + params[key] + '&'
    }

    return str
  },

  getDateFormat(value) {
    let obj = null
    if (value) {
      obj = new Date(value)
    } else {
      obj = new Date()
    }

    const year = obj.getFullYear()
    let month = obj.getMonth() + 1
    let day = obj.getDate()

    if (month < 10) {
      month = '0' + month
    }

    if (day < 10) {
      day = '0' + day
    }

    return year + '-' + month + '-' + day
  },

  getSelectOptions(data, text, value) {
    let arr = []
    for (const i in data) {
      arr.push({
        text: data[i][text],
        value: data[i][value]
      })
    }

    return arr
  },

  isAdmin() {
    return this.getUserProfile().isAdmin === true
  },

  buildSearchOptions(field_name, obj, res) {
    for (const i in obj.search_fields) {
      if (obj.search_fields[i].field === field_name) {
        obj.search_fields[i].options = res
        break
      }
    }
  },

  getSearchCompanyOptions(field_name, obj) {
    this.getCompanyOptions()
      .then(res => {
        this.buildSearchOptions(field_name, obj, res)
      })
  },

  async getSearchFacilityOptions(field_name, company_id, obj) {
    if (!company_id) {
      this.buildSearchOptions(field_name, obj, [])
      return []
    }

    const FacilityOptions = await this.getFacilityOptions(company_id)
    this.buildSearchOptions(field_name, obj, FacilityOptions)
    return FacilityOptions
  },

  getSearchMemberOptions(field_name, company_id, facility_id, obj) {
    if (!company_id || !facility_id) {
      this.buildSearchOptions(field_name, obj, [])
      return
    }

    this.getMemberOptions(company_id, facility_id)
      .then(res => {
        this.buildSearchOptions(field_name, obj, res)
      })
  },

  getSearchStaffOptions(field_name, company_id, facility_id, obj) {
    if (!company_id || !facility_id) {
      this.buildSearchOptions(field_name, obj, [])
      return
    }

    this.getStaffOptions(company_id, facility_id)
      .then(res => {
        this.buildSearchOptions(field_name, obj, res)
      })
  },

  getSearchFloorOptions(field_name, facility_id, obj) {
    if (!facility_id) {
      this.buildSearchOptions(field_name, obj, [])
      return
    }

    this.getFloorOptions(facility_id)
      .then(res => {
        this.buildSearchOptions(field_name, obj, res)
      })
  },

  getSearchRoomOptions(field_name, floor_id, obj) {
    if (!floor_id) {
      this.buildSearchOptions(field_name, obj, [])
      return
    }

    this.getRoomOptions(floor_id)
      .then(res => {
        this.buildSearchOptions(field_name, obj, res)
      })
  },

  getSearchMemberStatusOptions(field_name, obj) {
    return this.getMemberStatusOptions()
      .then(res => {
        this.buildSearchOptions(field_name, obj, res)
        return res
      })
  },

  getCompanyOptions() {
    return this.apiGetData('/company/GetCompanyDropDownList')
      .then(res => {
        return this.getSelectOptions(res.data.companys, 'companyName', 'companyID')
      })
  },

  getFacilityOptions(company_id) {
    if (!company_id) {
      return new Promise(() => {
        return []
      })
    }

    return this.apiGetData('/facility/GetFacilityDropDownList?companyID=' + company_id)
      .then(res => {
        return this.getSelectOptions(res.data.facilitys, 'facilityName', 'facilityID')
      })
  },

  getMemberOptions(company_id, facility_id) {
    if (!company_id || !facility_id) {
      return new Promise(() => {
        return []
      })
    }

    return this.apiGetData(`/member/GetMemberList?companyID=${company_id}&facilityID=${facility_id}`)
      .then(res => {
        return this.getSelectOptions(res.data.members, 'memberName', 'memberCode')
      })
  },

  getStaffOptions(company_id, facility_id) {
    if (!company_id || !facility_id) {
      return new Promise(() => {
        return []
      })
    }

    return this.apiGetData(`/staffmanage/GetStaffList?companyID=${company_id}&facilityID=${facility_id}&includeSelf=true`)
      .then(res => {
        return this.getSelectOptions(res.data.staffs, 'staffName', 'staffCode')
      })
  },

  getFloorOptions(facility_id) {
    if (!facility_id) {
      return new Promise(() => {
        return []
      })
    }

    return this.apiGetData('/bedfloor/GetFloorDropDownList?facilityID=' + facility_id)
      .then(res => {
        return this.getSelectOptions(res.data.floors, 'floorName', 'floorID')
      })
  },

  getRoomOptions(floor_id) {
    if (!floor_id) {
      return new Promise(() => {
        return []
      })
    }

    return this.apiGetData('/bedfloor/GetRoomDropDownList?floorID=' + floor_id)
      .then(res => {
        return this.getSelectOptions(res.data.rooms, 'roomName', 'roomID')
      })
  },

  getBedTypeOptions(facility_id) {
    if (!facility_id) {
      return new Promise(() => {
        return []
      })
    }

    return this.apiGetData('/bedtype/GetTypeDropDownList?facilityID=' + facility_id)
      .then(res => {
        return this.getSelectOptions(res.data.types, 'typeName', 'typeID')
      })
  },

  getBedOptions(room_id) {
    if (!room_id) {
      return new Promise(() => {
        return []
      })
    }

    return this.apiGetData('/bed/GetBedDropDownList?roomID=' + room_id)
      .then(res => {
        return this.getSelectOptions(res.data.beds, 'bedNumber', 'bedID')
      })
  },

  getGroupOptions(facility_id) {
    if (!facility_id) {
      return new Promise(() => {
        return []
      })
    }

    return this.apiGetData('/group/GetGroupDropDownList?facilityID=' + facility_id)
      .then(res => {
        return this.getSelectOptions(res.data.groups, 'groupName', 'groupID')
      })
  },

  getMemberStatusOptions() {
    return this.apiGetData('/member/GetMemberStatusDropDownList')
      .then(res => {
        return this.getSelectOptions(res.data.status, 'statusDesc', 'statusCode')
      })
  },

  refreshMenu(facility_id) {
    let url = '/staff/GetMenus'
    if (facility_id) {
      url += '?facilityID=' + facility_id
    } else {
      const role = this.getRole()
      facility_id = role.facilityID ? role.facilityID : ''
      url += role.facilityID ? '?facilityID=' + role.facilityID : ''
    }
    return this.apiGetData(url)
      .then(res => {
        const menus = res.data.menus
        let first_url = ''
        let first_route = ''
        let menu_name = {}

        for (const i in menus) {
          if (menus[i].subs.length === 0) {
            // 无子菜单
            menus[i].route = this.config.menu_map[menus[i].code]
              ? this.config.menu_map[menus[i].code].route
              : 'error-404'

            menus[i].icon = this.config.menu_map[menus[i].code]
              ? this.config.menu_map[menus[i].code].icon
              : 'SquareIcon'

            menu_name[menus[i].route] = menus[i].title

            if (!first_route) {
              first_route = menus[i].route
            }
          } else {
            // 有子菜单
            menus[i].icon = this.config.menu_map[menus[i].code]
              ? this.config.menu_map[menus[i].code].icon
              : 'SquareIcon'

            menus[i].children = menus[i].subs
            for (const k in menus[i].children) {
              menus[i].children[k].route = this.config.menu_map[menus[i].children[k].code]
                ? this.config.menu_map[menus[i].children[k].code].route
                : 'error-404'

              menus[i].children[k].icon = this.config.menu_map[menus[i].children[k].code]
                ? this.config.menu_map[menus[i].children[k].code].icon
                : 'SquareIcon'

              menu_name[menus[i].children[k].route] = menus[i].children[k].title

              if (!first_route) {
                first_route = menus[i].children[k].route
              }
            }
          }
        }

        this.setMenuName(menu_name)
        this.setMenuItems(menus)
        this.setMenuFirstRoute(first_route)

        // 不同院舍顯示不同名稱，如member可選會員，成員
        this.setModuleLabel(res.data.labels)

        return first_route
      })
  },

  setPermissions(params) {
    // return this.apiGetData('/staff/GetPermissions')
    return Vue.prototype.$requestWehealth({
      method: 'get',
      url: '/staff/GetPermissions',
      params,
    })
      .then(res => {
        let arr = []
        for (const i in res.data.permissions) {
          arr.push(res.data.permissions[i].code)
        }

        store.commit('app/SET_PERMISSIONS', arr)
        return true
      })
  },

  checkPermission(key) {
    if (!key) return true

    const permissions = store.state.app.permissions
    return permissions.includes(key)
  },

  setAdminCompanyId(val) {
    store.commit('app/SET_ADMIN_COMPANY_ID', val)
  },

  getAdminCompanyId() {
    return store.state.app.adminCompanyId ? store.state.app.adminCompanyId : 0
  },

  setAdminFacilityId(val) {
    if (![null, undefined, this.getAdminFacilityId()].includes(val)) {
      srUpdateFilter(val)
    }
    store.commit('app/SET_ADMIN_FACILITY_ID', val)
  },

  getAdminFacilityId() {
    return store.state.app.adminFacilityId ? store.state.app.adminFacilityId : null
  },

  getCompanyId() {
    return this.isAdmin() ? this.getAdminCompanyId() : this.getRole().companyID
  },

  getFacilityId() {
    return this.isAdmin() ? this.getAdminFacilityId() : this.getRole().facilityID
  },

  getLocale() {
    return store.state.app.locale ? store.state.app.locale : 'zh_HK'
  },

  setLocale(val) {
    if (!val) {
      val = 'zh_TW'
    }
    store.commit('app/SET_LOCALE', val)
  },

  transDateTime(val) {
    const date = new Date(val)
    const year = date.getFullYear()
    const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
    const day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
    const hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
    const minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
    const second = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()

    return year + '-'
      + month + '-'
      + day + ' '
      + hour + ':'
      + minute + ':'
      + second
  },

  // 不同院舍顯示不同名稱，如member可選會員，成員
  setModuleLabel(labels) {
    let labels_map = {}
    for (const i in labels) {
      labels_map[labels[i].moduleCode] = labels[i].moduleLabel
    }

    const module_map = {
      // 服務器: 本地
      'Mem_Menu': {
        code: 'member',
        default: {
          'zh_HK': '會員',
          // 'zh_TW': '會員',
          // 'zh_CN': '会员',
          'en': 'member'
        }
      }
    }
    const message = i18n.getLocaleMessage(i18n.locale)
    let obj = {}

    for (const i in module_map) {
      const old_value = message['common'][module_map[i].code]
      const new_value = labels_map[i] ? labels_map[i] : module_map[i]['default'][i18n.locale]
      const new_message = JSON.parse((JSON.stringify(message)).replaceAll(old_value, new_value))

      Object.assign(obj, new_message)
    }

    store.commit('app/SET_MODULE_LABEL', obj)
    store.commit('app/REFRESH_MODULE_LABEL')
  },

  setTableCondition(obj) {
    const val = {
      title: obj.title,
      search_condition: obj.getSearchCondition(),
      sort_condition: obj.sort_condition,
      page_condition: obj.page_condition
    }

    store.commit('app/SET_TABLE_CONDITION', val)
  },

  getTableCondition() {
    const condition = store.state.app.tableCondition
    store.commit('app/SET_TABLE_CONDITION', {})
    return condition
  },

  logout({ method = 'replace' } = {}) {
    this.clearSpecificLocalStorageItems()
    srStop(false)
    switch (method) {
      case 'replace':
        this.showLoading()
        location.replace('/login')
        break
      default:
        router.push({ name: 'login' })
        break
    }
  },

  setImpersonated(val) {
    store.commit('app/SET_IMPERSONATED', val)
  },

  getImpersonated() {
    return store.state.app.isImpersonated
  },

  isShowImpersonated() {
    // 只想 dev 支持模拟登录功能时放出这段代码
    // return this.isAdmin() && process.env.VUE_APP_API_ENV === 'Dev'

    // 想 dev 和 uat 都支持模拟登录功能时放出这段代码
    return this.isAdmin()
  },

  log() {
    const arr = ['[' + Vue.prototype.$moment()
      .format('YYYY-MM-DD HH:mm:ss') + ']'].concat(Array.from(arguments))
    console.log.apply({}, arr)
  },

  encrypt,
  decrypt,

  syncSearchFields(arg) {
    for (let key in arg) {
      arg[key] = arg[key] === undefined ? null : arg[key]
    }
    const config = Object.assign({}, {
      companyID: arg.facilityID ? this.getCustomConfigByKey('companyID') : null,
      facilityID: arg.floorID || arg.memberCode || arg.staffCode ? this.getCustomConfigByKey('facilityID') : null,
      memberCode: null,
      staffCode: null,
      floorID: arg.roomID ? this.getCustomConfigByKey('floorID') : null,
      roomID: null,
      BEMonitorIsOnBed: null,
      BEMonitorIsOnline: null,
    }, arguments[0])
    if (this.isAdmin()) {
      this.setAdminCompanyId(config.companyID)
      this.setAdminFacilityId(config.facilityID)
    }
    this.assignCustomConfigs(config)
  },

  async searchChangeField({
    fieldName,
    newValue,
    currentVm,
    searchVm,
    isSyncGlobally = true,
  }) {
    let [
      newCompanyID,
      newFacilityID,
      newMemberCode,
      newStaffCode,
      newFloorID,
      newRoomID
    ] = [null, null, null, null, null, null]
    const existFieldFacility = searchVm.fields.find(f => f.field === 'facilityID')
    const existFieldMember = searchVm.fields.find(f => f.field === 'memberCode')
    const existFieldStaff = searchVm.fields.find(f => f.field === 'staffCode')
    const existFieldFloor = searchVm.fields.find(f => f.field === 'floorID')
    const existFieldRoom = searchVm.fields.find(f => f.field === 'roomID')

    if (fieldName === 'companyID') {
      if (existFieldFacility) searchVm.list.facilityID = null
      if (existFieldMember) searchVm.list.memberCode = null
      if (existFieldStaff) searchVm.list.staffCode = null
      if (existFieldFloor) searchVm.list.floorID = null
      if (existFieldRoom) searchVm.list.roomID = null

      newCompanyID = newValue
      if (existFieldFacility) {
        const FacilityOptions = await this.getSearchFacilityOptions('facilityID', newValue, currentVm)
        const DefaultFacilityId = typeof (FacilityOptions[0]?.value) === 'number' ? FacilityOptions[0].value : null
        searchVm.list.facilityID = DefaultFacilityId
        newFacilityID = DefaultFacilityId
        if (existFieldMember) {
          this.getSearchMemberOptions('memberCode', newCompanyID, DefaultFacilityId, currentVm)
        }
        if (existFieldStaff) {
          this.getSearchStaffOptions('staffCode', newCompanyID, DefaultFacilityId, currentVm)
        }
        if (existFieldFloor) {
          this.getSearchFloorOptions('floorID', DefaultFacilityId, currentVm)
          this.getSearchRoomOptions('roomID', null, currentVm)
        }
      }
    } else if (fieldName === 'facilityID') {
      if (existFieldMember) searchVm.list.memberCode = null
      if (existFieldStaff) searchVm.list.staffCode = null
      if (existFieldFloor) searchVm.list.floorID = null
      if (existFieldRoom) searchVm.list.roomID = null

      newCompanyID = this.getCompanyId()
      newFacilityID = newValue
      if (existFieldMember) {
        this.getSearchMemberOptions('memberCode', newCompanyID, newFacilityID, currentVm)
      }
      if (existFieldStaff) {
        this.getSearchStaffOptions('staffCode', newCompanyID, newFacilityID, currentVm)
      }
      if (existFieldFloor) {
        this.getSearchFloorOptions('floorID', newValue, currentVm)
        this.getSearchRoomOptions('roomID', null, currentVm)
      }
    } else if (fieldName === 'memberCode') {
      newCompanyID = this.getCompanyId()
      newFacilityID = this.getFacilityId()
      newMemberCode = newValue
    } else if (fieldName === 'staffCode') {
      newCompanyID = this.getCompanyId()
      newFacilityID = this.getFacilityId()
      newStaffCode = newValue
    } else if (fieldName === 'floorID') {
      if (existFieldRoom) searchVm.list.roomID = null

      newCompanyID = this.getCompanyId()
      newFacilityID = this.getFacilityId()
      newFloorID = newValue
      if (existFieldRoom) {
        this.getSearchRoomOptions('roomID', newValue, currentVm)
      }
    } else if (fieldName === 'roomID') {
      newCompanyID = this.getCompanyId()
      newFacilityID = this.getFacilityId()
      newFloorID = this.getCustomConfigByKey('floorID')
      newRoomID = newValue
    }

    const res = {
      companyID: newCompanyID,
      facilityID: newFacilityID,
      memberCode: newMemberCode,
      staffCode: newStaffCode,
      floorID: newFloorID,
      roomID: newRoomID,
    }

    if (isSyncGlobally) {
      this.syncSearchFields(res)
    }

    return res
  },

  syncFieldsOfDateRange({
    dayRange,
    changedDateType,
    changedDateStr,
    vmDateWrapperObj,
    vmStartDateConfig,
    vmEndDateConfig
  }) {
    // handle "clear date"
    if (changedDateStr === '') {
      vmDateWrapperObj.startDate = ''
      vmDateWrapperObj.endDate = ''
    }

    // syncBoundsOfDateRange
    const currentMoment = Vue.prototype.$moment()
      .add(1, 'hours')
      // .add(99, 'years') // just for test
    const startDate = vmDateWrapperObj.startDate
    const endDate = vmDateWrapperObj.endDate

    if (vmStartDateConfig) {
      vmStartDateConfig.maxDate = endDate
        ? endDate
        : Vue.prototype.$moment(currentMoment).format('YYYY-MM-DD')
      vmStartDateConfig.minDate = endDate
        ? Vue.prototype.$moment(endDate).add(-(dayRange - 1), 'days').format('YYYY-MM-DD')
        : undefined
    }

    if (vmEndDateConfig) {
      const maxMoment = startDate
        ? Vue.prototype.$moment(startDate).add(dayRange - 1, 'days')
        : currentMoment
      vmEndDateConfig.maxDate = maxMoment.diff(currentMoment) > 0
        ? Vue.prototype.$moment(currentMoment).format('YYYY-MM-DD')
        : Vue.prototype.$moment(maxMoment).format('YYYY-MM-DD')
      vmEndDateConfig.minDate = startDate
        ? Vue.prototype.$moment(startDate).format('YYYY-MM-DD')
        : undefined
    }
  },

  getCustomConfigs() {
    return store.getters['app/getCustomConfigs']
  },
  getCustomConfigByKey(key) {
    return store.getters['app/getCustomConfigByKey'](key)
  },
  assignCustomConfigs(configObj) {
    store.commit('app/ASSIGN_CUSTOM_CONFIGS', configObj)
  },

  getTablePerPage() {
    let table_per_page = this.getCustomConfigByKey('table_per_page')
    return this.config.table_per_page_dropdown.includes(table_per_page) ? table_per_page : this.config.table_per_page
  },
  setTablePerPage(size) {
    this.assignCustomConfigs({ 'table_per_page': size })
  },

  sortModules(modules) {
    const temp = []
    for (const [_key, module] of modules.entries()) {
      if (module.parentID === null) {
        temp.push(module)
        for (const [_subKey, subModule] of modules.entries()) {
          if (subModule.parentID === module.moduleID) {
            temp.push(subModule)
          }
        }
      }
    }
    return temp
  },
}
