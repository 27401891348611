export default [
  {
    name: 'kioskNursingWork',
    path: '/kiosk/nursingWork',
    component: () => import('@/views/kiosk/kskNursingWork/Index'),
  },

  {
    name: 'kioskNursingWorkLog',
    path: '/kiosk/nursingWorkLog',
    component: () => import('@/views/kiosk/kskNursingWorkLog/Index'),
  },

  {
    name: 'kioskRecord',
    path: '/kiosk/record',
    component: () => import('@/views/kiosk/kskRecord/Index'),
  },

  {
    name: 'kioskEnvironment',
    path: '/kiosk/environment',
    component: () => import('@/views/kiosk/kskEnvironment/Index'),
  },
  {
    name: 'kioskEnvironment_add',
    path: '/kiosk/environment/add',
    component: () => import('@/views/kiosk/kskEnvironment/Add'),
  },
  {
    name: 'kioskEnvironment_edit',
    path: '/kiosk/environment/edit/:token',
    component: () => import('@/views/kiosk/kskEnvironment/Edit'),
  },

  {
    name: 'kioskFacility',
    path: '/kiosk/facility',
    component: () => import('@/views/kiosk/kskFacility/Index'),
  },
  {
    name: 'kioskFacility_edit',
    path: '/kiosk/facility/edit/:id',
    component: () => import('@/views/kiosk/kskFacility/Edit'),
  },

  {
    name: 'kioskReport',
    path: '/kiosk/report',
    component: () => import('@/views/kiosk/kskReport/Index'),
  },

  {
    name: 'kioskManagePackage',
    path: '/kiosk/managePackage',
    component: () => import('@/views/kiosk/kskManagePackage/Index'),
  },
  {
    name: 'kioskManagePackage_add',
    path: '/kiosk/managePackage/add',
    component: () => import('@/views/kiosk/kskManagePackage/Add'),
  },
  {
    name: 'kioskManagePackage_edit',
    path: '/kiosk/managePackage/edit/:id',
    component: () => import('@/views/kiosk/kskManagePackage/Edit'),
  },

  {
    name: 'kioskManageVersion',
    path: '/kiosk/manageVersion',
    component: () => import('@/views/kiosk/kskManageVersion/Index'),
  },
  {
    name: 'kioskManageVersion_upgrade',
    path: '/kiosk/manageVersion/upgrade',
    component: () => import('@/views/kiosk/kskManageVersion/Upgrade'),
  },
  {
    name: 'kioskManageVersion_upgrade_one',
    path: '/kiosk/manageVersion/upgradeOne/:token/:platform',
    component: () => import('@/views/kiosk/kskManageVersion/UpgradeOne'),
  },

  {
    name: 'kioskManageAboutUs',
    path: '/kiosk/manageAboutUs',
    component: () => import('@/views/kiosk/kskManageAboutUs/Index'),
    meta: {
      // hideInNodeEnvProduction: true
    },
  },
  {
    name: 'kioskMemberHealthData',
    path: '/kiosk/memberHealthData',
    component: () => import('@/views/kiosk/kskMemberHealthData/Index'),
  },
  {
    name: 'kioskMemberHealthDataReport',
    path: '/kiosk/memberHealthDataReport/:memberId',
    component: () => import('@/views/kiosk/kskMemberHealthData/Report'),
  },
  {
    name: 'kioskMemberNfc',
    path: '/kiosk/memberNfc',
    component: () => import('@/views/kiosk/kskMemberNfc/Index'),
  },
  {
    name: 'kioskMemberNfc_edit',
    path: '/kiosk/memberNfc/edit/:id',
    component: () => import('@/views/kiosk/kskMemberNfc/Edit'),
  },
  {
    name: 'kioskMemberQr',
    path: '/kiosk/memberQr',
    component: () => import('@/views/kiosk/kskMemberQr/Index'),
  },
  {
    name: 'kioskMemberQr_edit',
    path: '/kiosk/memberQr/edit/:id',
    component: () => import('@/views/kiosk/kskMemberQr/Edit'),
  },
  {
    name: 'kioskMemberQrPreview',
    path: '/kiosk/memberQr/Preview',
    component: () => import('@/views/kiosk/kskMemberQr/Preview'),
    meta: {
      layout: 'full',
      auth: false,
      isStandalone: true,
    },
  },
  {
    name: 'kioskMemberFaceId',
    path: '/kiosk/memberFaceId',
    component: () => import('@/views/kiosk/kskMemberFaceId/Index'),
    meta: {
      // hideInNodeEnvProduction: true
    },
  },
  {
    name: 'kioskMemberFaceId_edit',
    path: '/kiosk/memberFaceId/edit/:id',
    component: () => import('@/views/kiosk/kskMemberFaceId/Edit'),
  },
  {
    name: 'kioskMemberFaceId_ManageCollectionId',
    path: '/kiosk/memberFaceId/manageCollectionId',
    component: () => import('@/views/kiosk/kskMemberFaceId/kskManageCollectionId/Index.vue'),
  },
  {
    name: 'kioskMemberFaceId_ManageCollectionId_edit',
    path: '/kiosk/memberFaceId/manageCollectionId/edit/:id',
    component: () => import('@/views/kiosk/kskMemberFaceId/kskManageCollectionId/Edit.vue'),
  },

  {
    name: 'kioskMemberExternal',
    path: '/kiosk/memberExternal',
    component: () => import('@/views/kiosk/kskMemberExternal/Index'),
  },
  {
    name: 'kioskMemberExternal_batchUpload',
    path: '/kiosk/memberExternal/batchUpload',
    component: () => import('@/views/kiosk/kskMemberExternal/BatchUpload'),
  },
  {
    name: 'kioskMemberExternal_edit',
    path: '/kiosk/memberExternal/edit/:id',
    component: () => import('@/views/kiosk/kskMemberExternal/Edit'),
  },

  {
    name: 'kioskPeripheral',
    path: '/kiosk/peripheral',
    component: () => import('@/views/kiosk/kskPeripheral/Index'),
    meta: { hideInNodeEnvProduction: true },
  },
  {
    name: 'kioskPeripheral_edit',
    path: '/kiosk/peripheral/edit/:id',
    component: () => import('@/views/kiosk/kskPeripheral/Edit'),
    meta: { hideInNodeEnvProduction: true },
  },

  {
    name: 'kioskPeripheralModel',
    path: '/kiosk/peripheralModel',
    component: () => import('@/views/kiosk/kskPeripheralModel/Index'),
    meta: { hideInNodeEnvProduction: true },
  },
  {
    name: 'kioskPeripheralModel_add',
    path: '/kiosk/peripheralModel/add',
    component: () => import('@/views/kiosk/kskPeripheralModel/Add'),
    meta: { hideInNodeEnvProduction: true },
  },
  {
    name: 'kioskPeripheralModel_edit',
    path: '/kiosk/peripheralModel/edit/:id',
    component: () => import('@/views/kiosk/kskPeripheralModel/Edit'),
    meta: { hideInNodeEnvProduction: true },
  },
]
